<template>
    <transition name="fade">
        <div class="date-point" :id="`date-point-${index}`"
        v-bind:style="{
            ...pointStyles
        }">
            <div class="range-mark" v-bind:style="{
                ...markStyles
            }"></div>
        </div>
    </transition>
</template>
<script>
import CalendarMonth from '../../../../scripts/calendarMonth';
import CalendarWeek from '../../../../scripts/calendarWeek';

export default {
    name: 'DatePoint',
    props: {
        date: {
            type: Date,
            required: true,
        },
        baseLeft: {
            type: Number,
            required: true,
        },
        itemsContainer: {
            type: HTMLElement,
        },
        weekItemWidth: {
            type: Number,
            required: true,
        },
        weekItemHeight: {   
            type: Number,
            required: true,
        },
        deltaDates: {
            type: Number,
        },
        index: {
            type: Number,
            default: 0,
        },
        pairedIndex: {
            type: Number,
        },
        daysCount: {
            type: Number,
            default: 1,
        },
        isHideMark: {
            type: Boolean,
            default: false,
        },
        currentRange: {	
			type: CalendarWeek|CalendarMonth,
			required: true,
		},
    },
    data() {
        return {
            isMounted: false,
            markStyles: null,
            legendWidth: 0,
            moveLimits: null,
        }
    },
    mounted() {
        this.isMounted = true
        this.setupMarkStyles()
        this.legendWidth = this.calcLegendWidth()
        this.$nextTick(() => {
            if (this.legendWidth == 0) {
                this.legendWidth = this.calcLegendWidth()
            }
            this.legendWidth = this.calcLegendWidth()
            this.moveLimits = this.calcMoveLimits()
        })
    },
    computed:{

        isRangeWeek(){
            return this.currentRange instanceof CalendarWeek
        },

		pointStyles(){
            this.date
            this.daysCount
			this.itemsContainer
			this.weekItemWidth
			
			const result = {
                display: 'none'
            }

            if (!this.isMounted) {
                return result
            }

            const rect = this.initRect
            if (!rect || Number.isNaN(rect.width) || Number.isNaN(rect.height)) {
                // console.log('no point styles : rect is null', rect);
                return result
            }

            const pos = this.finishedPos
            if (!pos || pos.left == undefined || pos.top == undefined || Number.isNaN(pos.top) || Number.isNaN(pos.left)) {
                // console.log('no point styles : pos is null', pos);
                return result
            }

            result["left"] = pos.left + 'px'
            result["top"] = this.isRangeWeek ? 0 : pos.top
            result["width"] = pos.width + 'px'
            result["height"] = 'calc(20% - 2px)'
            result["min-height"] = this.weekItemHeight + 'px'
            result["display"] = 'block'

            this.$nextTick(this.setupMarkStyles)
            
            // console.log('point styles :>> ', result);

			return result
		},
        markIsVisible(){
            this.pointStyles
            this.daysCount
			this.itemsContainer
			this.weekItemWidth

            if (this.isHideMark) {
                return false
            }

            if (!this.itemsContainer) {
                // console.log(`mark ${this.date.toLocaleDateString()} is invisible : no item container`, false );
                return false
            }
            const pos = this.finishedPos
            if (!pos) {
                // console.log(`mark ${this.date.toLocaleDateString()} is invisible : no final pos`, false );
                return false
            }

            // console.log(`mark ${this.date.toLocaleDateString()} is visible`, pos.top >= 0 && Math.abs(pos.top - containerRect.height) > 10);

            return true
        },
        initRect(){

            // Initial values of the DatePoint based on the date
            const rect = this.calcRect()
            if (!rect)
                return null
			rect.left += this.baseLeft
            rect.left += 4 // carousel component padding

            return rect
        },
        relativePos(){
            this.baseLeft
            // Relative position of the DatePoint on the current slide
            if (!this.itemsContainer || !this.moveLimits) {
                return null
            }
            const initRect = this.initRect
            const posInCurrentRange = this.currentRange.getDatePosition(this.date)
            if (!posInCurrentRange) {
                return null
            }
            const left = initRect.left
            const top = posInCurrentRange.y * 20
            if (Number.isNaN(left) || Number.isNaN(top)) {
                return null
            }
            return {
                left: left - this.baseLeft,
                top: top,
                leftLimit: this.moveLimits.left,
                rightLimit: this.moveLimits.right,
            }
        },
        finishedPos(){
            this.baseLeft
            // Final position of the DatePoint
            const sizes = this.initRect
            if (!sizes) {
                // console.log("no finished pos : sizes is null");
                return null
            }

            const posInCurrentRange = this.currentRange.getDatePosition(this.date)
            if (!posInCurrentRange) {
                return null
            }
            
            const posYInCurrentRange = posInCurrentRange.y * 20
            // console.log('left :>> ',  sizes.left);

            const delta = this.deltaDates
            if (delta == undefined) {
                return {
                    top: `max(${sizes.top + "px"}, ${posYInCurrentRange}%)`,
                    left: sizes.left,
                    width: sizes.width,
                    height: sizes.height,
                }
            }
            const relativePos = this.relativePos
            if (!relativePos) {
                // console.log("no finished pos : relative pos is null");
                return null
            }

            sizes.top = posYInCurrentRange
            const movementPos = this.calcMovementPos(relativePos, sizes, delta)
            movementPos.left += this.baseLeft
            movementPos.top += "%"
            return movementPos
        },
        containerGap(){
            if (!this.itemsContainer)
                return null

            const week = this.itemsContainer.querySelector('.schedule-week')
            if (!week)
                return null
            const weekContent = week.querySelector('.week-content')
            if (!weekContent)
                return null
            const weekStyles = window.getComputedStyle(weekContent)
            const gap = parseFloat(weekStyles.getPropertyValue("gap"))
            return gap
        },

    },

    methods: {
        getPosLeft(elem) {
			if (!this.itemsContainer)
				return null
			
			const leftContainer = this.itemsContainer.getBoundingClientRect().left
			const leftElem = elem.getBoundingClientRect().left
			return Math.round(leftElem - leftContainer)
		},
		getPosTop(elem) {
			if (!this.itemsContainer)
				return null
			
			const topContainer = this.itemsContainer.getBoundingClientRect().top
			const topElem = elem.getBoundingClientRect().top
			return  Math.round(topElem - topContainer) 
		},
		getDateChild(date){
			if (!this.itemsContainer)
				return null

			const currentSlide = this.itemsContainer.querySelector('.carousel-slide.is-active')
			if (!currentSlide)
				return null

			const datesElements = Array.from(currentSlide.querySelectorAll(`.schedule-date`))
			if (!datesElements || datesElements.length == 0)
				return null
			const dateElem = datesElements.find(elem => {
				return elem.id == `date-${date.toLocaleDateString()}`
			})
        
            if (!dateElem) {
                
                const allDatesElements = Array.from(this.itemsContainer.querySelectorAll(`.schedule-date`))
                if (!allDatesElements || allDatesElements.length == 0)
                    return null
                return allDatesElements.find(elem => {
                    return elem.id == `date-${date.toLocaleDateString()}`
                })
            }

			return dateElem
		},

		calcRect(){
			if (!this.itemsContainer || !this.weekItemWidth)
				return null

			const elem = this.getDateChild(this.date)
			if (!elem)
				return null
        
            const dayOfWeek = Math.max(this.date.getDay() == 0? 
                6 : this.date.getDay() - 1, 0)

            const startLeft = Math.round(dayOfWeek * (this.weekItemWidth + this.containerGap) + this.legendWidth)
            const startTop = this.getPosTop(elem)

			return {
				left : startLeft,
				width: this.weekItemWidth,
                height: this.weekItemHeight,
				top : Math.round(startTop),
			}
		},

        calcMovementPos(pos, sizes, deltaDates){
            
            // How many columns passed during the movement
            
            // Left pos value of DatePoint during the movement
            // deltaDates - movement progress in count of the date columns

            let movementLeft = Math.round(pos.left + deltaDates * this.weekItemWidth )
            let movementTop = Math.round(pos.top)

            if (this.isRangeWeek) {
                return {
                    left: movementLeft,
                    top: movementTop,
                    width: sizes.width,
                    height: sizes.height,
                }
            }

            // If going out of the left limit -> replace to the right limit and higher
            // console.log('init first :>> ', {movementLeft, movementTop, pos});
            while (movementLeft < pos.leftLimit ) {
                movementLeft = movementLeft - pos.leftLimit + pos.rightLimit
                movementLeft += this.containerGap * 2
                movementTop -= 20
                // console.log('first :>> ', {movementLeft, movementTop});
            }
            
            // If going out of the right limit -> replace to the left limit and lower
            // console.log('init second :>> ', {movementLeft, movementTop, pos});
            while (movementLeft > pos.rightLimit) {
                movementLeft = movementLeft - pos.rightLimit + pos.leftLimit
                movementLeft -= this.containerGap * 2
                movementTop += 20
                // console.log('second :>> ', {movementLeft, movementTop});
            }

            // If container has a gap between dates -> adjust the left position
            const leftDiff = movementLeft - pos.left
            const passedDatesDiff = leftDiff / this.weekItemWidth
            movementLeft += passedDatesDiff * this.containerGap

            return {
                left: movementLeft,
                top: movementTop,
                width: sizes.width,
                height: sizes.height,
            }
        },

        calcLegendWidth(){
            if (!this.itemsContainer)
                return null

            const legend = this.itemsContainer.querySelector('.week-number')
            if (!legend)
                return null

            return Math.round(legend.getBoundingClientRect().width)
        },
        calcMoveLimits(){
            if (!this.itemsContainer)
                return null

            const legendWidth = this.legendWidth
            const week = this.itemsContainer.querySelector('.schedule-week')
            if (!week)
                return null
            const weekRect = week.getBoundingClientRect()
            const weekStyles = window.getComputedStyle(week)
            const weekPaddingLeft = parseFloat(weekStyles.getPropertyValue('padding-left'))
            const weekPaddingRight = parseFloat(weekStyles.getPropertyValue('padding-right'))

            const result = {
                left: Math.round(legendWidth),
                right: Math.round(weekRect.width - legendWidth - weekPaddingLeft - weekPaddingRight),
            }

            // console.log('result :>> ', result, this.$el);

            return result
        },

        getPairedElem(){
            if (!this.itemsContainer || !this.pairedIndex)
                return null
            return this.itemsContainer.querySelector(`#date-point-${this.pairedIndex}`)
        },
        
        setupMarkStyles(){
            let tries = 0
            const markStyles = this.calcMarkStyles()
            if (markStyles) {
                this.markStyles = markStyles
                return
            }
            const interval = setInterval(() => {
                const markStyles = this.calcMarkStyles()
                if (markStyles || markStyles == null) {
                    this.markStyles = markStyles
                    clearInterval(interval)
                }
                if (tries > 5) {
                    clearInterval(interval)
                }
                tries++
            }, 100)
        },

        calcMarkStyles() {
            if (!this.pointStyles || !this.isMounted) {
                return undefined
            }

            // This function working in 2 modes:
            //
            // 1. If selected dates inside one week, we just need to display
            // simple mark that will cover all date range.
            //
            // 2. If selected dates on the different weeks, we need to display
            // splitted mark that will cover selected dates on the current week and remained dates on another.

            if (this.daysCount == 1) {
                return this.fullMarkStyles()
            }

            const pairedElem = this.getPairedElem()
            if (!pairedElem) {
                return undefined
            }


            let result = null
            const currentTop = this.getPosTop(this.$el)
            const pairedElemTop = this.getPosTop(pairedElem)

            if (Number.isNaN(pairedElemTop) || Number.isNaN(currentTop)) {
                return undefined
            }

            const isWeekTransition = Math.abs(pairedElemTop - currentTop) > 1 && !this.isRangeWeek
            const isOneMarkAlreadySet = this.pairedIndex < this.index

            if (!isWeekTransition && isOneMarkAlreadySet){
                return null
            }

            if (!isWeekTransition) {
                result = this.fullMarkStyles(pairedElem)
            } else if (!isOneMarkAlreadySet) {
                result = this.firstPartMarkStyles()    
            } else {
                result = this.secondPartMarkStyles()
            }

            if (!result) {
                return null
            }

            result['display'] = 'block'
            result['border-color'] = this.markIsVisible ?
                null : 'transparent' 
            return result
        },
        fullMarkStyles(pairedElem){
            const result = {}
            const currentLeft = this.getPosLeft(this.$el)
            if (!pairedElem && this.daysCount == 1) {
                result.left = '0'
                result.width = `${this.weekItemWidth}px`
                result.display = 'block'
                return result
            }
            const pairedLeft = this.getPosLeft(pairedElem)
            if (Number.isNaN(currentLeft) || Number.isNaN(pairedLeft)) {
                return undefined
            }

            result.width = `${pairedLeft - currentLeft + this.weekItemWidth}px`
            result.left = '0'
            result.transition = 'none'
            return result
        },
        firstPartMarkStyles(){
            const result = {}
            const currentLeft = this.getPosLeft(this.$el)
            if (Number.isNaN(currentLeft) || !this.relativePos) {
                return undefined
            }
            result.transition = 'none'
            result.width = `${this.relativePos.rightLimit - currentLeft + this.weekItemWidth}px`
            result.left = '0'
            result["border-right"] = 'none'
            result["border-radius"] = '4px 0 0 4px'
            return result
        },
        secondPartMarkStyles(){
            const result = {}
            const currentLeft = this.getPosLeft(this.$el)
            if (Number.isNaN(currentLeft) || !this.relativePos) {
                return undefined
            }
            result.transition = 'none'
            result.width = `${currentLeft - this.relativePos.leftLimit + this.weekItemWidth}px`
            result.right = '0'
            result["border-left"] = 'none'
            result["border-radius"] = '0 4px 4px 0'
            return result
        },
	}
};
</script>
<style lang="css">
.date-point {
    position: absolute;
    background: transparent;
    border-radius: 4px;
    z-index: 10;
    pointer-events: none;
    transition: width 0.2s, max-height 0.2s;
    display: none;
    height: 100%;
}

.range-mark {
    position: absolute;
    top: 0;
    bottom: 0;
    border: 1px solid #0075ff;
    /* box-shadow: 0 0 0 1.5px #adcdf3; */
    border-radius: 4px;
    transition: width .3s cubic-bezier(.175,.885,.32,1.275);
    display: none;
}

.fade-enter-active {
    animation: fade .2s ease-in;
}

.fade-leave-active{
    animation: fade .2s ease-out reverse;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>