import { dateDifferenceInDays, dateRange, isEqualDate, weekNumber } from "./date"

class CalendarWeek {
	number
	from
	to

	$cacheRange=null

	/**
	 * Make week instance for using in calendar (basically contain 7 days range)
	 * @param {Date} from - start date of week
	 * @param {Date} to	- end date of week 
	 */
	constructor(from, to) {
		this.from = from
		this.to = to
		this.number = weekNumber(this.from)
	}

	range(){
		if (!this.$cacheRange)
			this.$rangeCache = dateRange(this.from, this.to)
		return this.$rangeCache
	}

	includes(date){

		if (!date) {
			return false
		}

		return isEqualDate(this.from, date) 
			|| isEqualDate(this.to, date) 
			|| (this.from < date && date < this.to)
	}

	getWeekNumber() {
		return this.number
	}

	getDatePosition(date) {

		if (!date || !this.includes(date)) {
			return undefined
		}

		const diff = dateDifferenceInDays(this.from, date)

		const x = diff % 7
		const y = Math.floor(diff / 7)

		return {x, y}
	}
}

export default CalendarWeek