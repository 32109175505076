<template>
	<div id="create-slot"
	@dragstart="() => false"
	v-bind:style="{
		top			: `${posY}px`,
		left 		: `${posX}px`,
		height		: `${cellHeight}px`,
		width		: `${columnWidth}px`,
	}"
	v-show="dragging"
	v-bind:class="{
		invalid: timeIsWrong,
		draggable: dragging,
	}">
		<div class="current-time-line" 
		v-bind:class="{
			invalid: timeIsWrong
		}"
		v-bind:style="{
			left: `${-posX}px`,
			right: `${columnWidth}px`,
		}">
			<div class="nearest-time" v-if="nearestPlace">
				Free time: {{ neatestPlaceTime }}
			</div>
			<div class="time-string">{{ time }}</div>
		</div>
	</div>
</template>

<script>
import { globalBus } from '../../GlobalEventBus';
import Calendar from '../../scripts/calendar';
import CalendarLoadedDates from '../../scripts/calendarLoadedDates';
import CalendarMonth from '../../scripts/calendarMonth';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import { isEqualDate, isEqualTime, nextDate, pair } from '../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../scripts/draggable';
import { scrollByCondition } from '../../scripts/scroll';
import { eventToCoordinates } from '../../scripts/slotCardPosition';
import TimePeriod from '../../scripts/timePeriod';
import eventBroker from '../calendar-v2/eventBus';
import { bus } from './EventBus';

const CONFIG = {
	"movement-step" : 10,
}

const SCROLL_STEPS = 3

export default {
	name: 'ScheduleCreateSlot',

	emits: [
		'on-drag-start',
		'on-drag-move',
		'on-drag-end',
	],

	props: {
		moveEvent: [PointerEvent, MouseEvent],
		loadedDates: {
			type: CalendarLoadedDates,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54, 
		},
		isDeletePreparation: {
			type: Boolean,
			default: false,
		},
		calendar: {
			type: Calendar,
			required: true,
		},
	},
	data() {
		return {
			posX: 0,
			posY: 0,

			isMovingDown: false,
			isMovingRight: false,

			timeIsWrong: false,

			slotContainer: null,
			containerSizes: null,

			scheduleTableHeaderHeight: 0,
			dragging: null,

			scrollInterval: null,
			scrollSpeedX: 0,
			scrolledX: 0,
			scrollSpeedY: 0,

			insertIndex: null,
			currentMinute: 0,
			currentHour: 0,
			columnsX: null,
			futureColumn: null,
			columnWidth: null,

			lastInvalidTimeDate: null,
			nearestPlace: null,
			isScrollBorderAreaX: false,
			isScrollBorderAreaY: false,
		};
	},

	computed: {
		time(){
			return `${this.currentHour}:${pair(this.currentMinute)}`
		},
		scrollStep(){
			return this.cellHeight / SCROLL_STEPS
		},
		slotCollection(){
			return this.calendar.slotCollection
		},
		neatestPlaceTime(){
			if (!this.nearestPlace)
				return null
			const nearestPlaceDate = this.nearestPlace.start.date
			return `${pair(nearestPlaceDate.getHours())}:${pair(nearestPlaceDate.getMinutes())}`
		},
	},

	mounted() {
		if (window.navigator.vibrate)
			window.navigator.vibrate(65)
		makeDraggable(this.$el, {
			start: this.handleStart,
			move: this.handleMove,
			end: this.handleEnd,
		}, {enablePointerEvents: true})
	},

	beforeDestroy() {
		clearInterval(this.scrollRepeat)
		makeUndraggable(this.$el, {
			start: this.handleStart,
			move: this.handleMove,
			end: this.handleEnd,
		}, {enablePointerEvents: true})
	},

	methods: {
		pair: pair,

		getCurrentSlotContainer() {
			const currentCalendar = document.querySelector('.calendar-v2')
			if (!currentCalendar)
				return null
			this.scheduleTableHeaderHeight = 0
			return currentCalendar.querySelector(".calendar-v2-table")
		},

		// getCurrentTableFrame(){
		// 	let scheduleTable = document.getElementById("schedule-table")
		// 	let currentCarouselSlide = scheduleTable.querySelector(".carousel-slide.is-active")
		// 	if (!currentCarouselSlide)
		// 		return null
			
		// 	return currentCarouselSlide.querySelector(".schedule-table-frame")
		// },

		handleStart(event){
			this.slotContainer = this.getCurrentSlotContainer()
			if (!this.slotContainer)
				return 

			let slotContainerRect = this.slotContainer.getBoundingClientRect()
			const columnElem = this.slotContainer.querySelector(".calendar-column")
			if (!columnElem)
				return
			this.columnWidth = this.loadedDates.selected.daysCount == 1 ? 
				columnElem.offsetWidth * 0.5 : 
				columnElem.offsetWidth  

			const deleteArea = document.querySelector('.delete-slot')
			
			const hours = this.slotContainer.querySelector('.calendar_hours_ruler')
			const hoursRect = hours.getBoundingClientRect()

			let sizes = {}
			sizes["width"] = Math.round(slotContainerRect.width - this.columnWidth)
			sizes["height"] = Math.round(slotContainerRect.height - this.cellHeight - this.scheduleTableHeaderHeight)
			sizes["left"] = hoursRect.width
			sizes["right"] = slotContainerRect.width - this.columnWidth
			sizes["scroll-area-y"] = Math.round(this.slotContainer.scrollHeight)
			sizes["delete-height"] = Math.round(deleteArea.getBoundingClientRect().height)
			this.containerSizes = sizes

			// Init start position of a drag and drop
			let {x, y} = eventToCoordinates(event);

			this.columnsX = Array.from(this.slotContainer.querySelectorAll(".calendar-column"))
				.map((column) => column.getBoundingClientRect().left)

			this.posX = x - this.columnWidth / 2
			this.posY = y - slotContainerRect.top - this.cellHeight / 2

			// Init start point 

			this.dragging = {dx: this.posX - x, dy: this.posY - y}
			this.$emit('on-drag-start')

			eventBroker.$emit('scrollable-table', 'enable-slot-move-scroll', this.updateLimits)
			eventBroker.$emit('scrollable-table', 'enable-create-slot')
		},

		handleMove(event){
			if (!this.dragging)
				return
			let {x, y} = eventToCoordinates(event);

			let maxX = this.containerSizes["right"]
			let minX = this.containerSizes["left"]
			let maxY = this.containerSizes["height"]+56
			let minY = this.scheduleTableHeaderHeight + 12

			
			let posX = Math.round(Math.max(minX, Math.min(x + this.dragging.dx, maxX)))
			let posY = Math.round(Math.max(minY, Math.min(y + this.dragging.dy, maxY)))

			this.isMovingDown = posY > this.posY
			this.isMovingRight = posX > this.posX
			this.posY = posY
			this.posX = posX
			
			this.futureColumn = this.calcFutureColumn(this.posX, this.scrolledX)
			this.setTimeForPosition(this.posY - 16.5, this.futureColumn)
			
			this.scrollContainer()
			
			if (!this.scrollInterval) {
				this.sendMoveEvent(posX, posY)
			}
			// this.handlePutNewSlot()
		},

		sendMoveEvent(posX, posY){
			this.$emit('on-drag-move', {posX, posY})
			if (this.slotCollection && this.timeIsWrong) {
				const originDate = this.futureColumn
				const date = new Date(originDate)
				date.setHours(this.currentHour, this.currentMinute)
				const period = new TimePeriod(date, -1, 60)
				this.nearestPlace = this.slotCollection.nearestFreePlace(period)
			} else {
				this.nearestPlace = null
			}
			
		},

		calcFutureColumn(posX, scrolledX){

			if (this.columnsX && this.columnsX.length == 1)
				return this.loadedDates.selected.start.date

			const closestColumn = this.columnsX.map((columnX, index) => {
				return {
					index: index,
					diff: Math.abs(columnX - posX - scrolledX)
				}
			}).sort((a, b) => a.diff - b.diff)[0]
			const visibleDates = this.loadedDates.visibleDateRange()
			// console.log('ob :>> ', {
			// 	visibleDates,
			// 	closestColumn,
			// });
			const closestColumnDate = visibleDates[closestColumn.index]
			this.colorizeFutureColumn(closestColumnDate)
			return closestColumnDate
		},

		colorizeFutureColumn(futureColumn){

			if (this.columnsX && this.columnsX.length == 1)
				return

			const columnTitles = Array.from(this.slotContainer.querySelectorAll('.calendar-date-title__item'))
			if (!futureColumn) {
				columnTitles.forEach((titleElem) => {
					titleElem.classList.remove('selected')
				})
				return
			}

			columnTitles.forEach((titleElem) => {
				titleElem.classList.remove('selected')
			})

			const columnDate = columnTitles.find((titleElem) => {
				const id = futureColumn.toLocaleDateString()
				return titleElem.id == `calendar-date-title__item-${id}`
			})
			if (!columnDate)
				return
			columnDate.classList.add('selected')
		},

		// handlePutNewSlot(){
		// 	let slotContainer = this.getCurrentSlotContainer()
		// 	let slot = slotContainer.querySelector(".slot")
		// 	let supportMessage = this.getCurrentTableFrame().querySelector(".message.support")
		// 	if (!slot && !supportMessage) {
		// 		return this.showSupportMessage()
		// 	}
		// 	if (!slot) {
		// 		return
		// 	}

		// 	this.handleInsertNewSlot()			
		// },

		showSupportMessage(){
			let tableFrameIndex=this.loadedDates.selected.start.date.toLocaleDateString()
			bus.$emit(`table-frame-${tableFrameIndex}`, 'show-support-message', true)
		},

		

		checkValidTime(futureColumnDate, hour, minute){

			const dateIsPast = () => {
				const closestCreateTime = new Date()
				const current = new Date(futureColumnDate)
				current.setHours(hour, minute, 0, 0)
				return closestCreateTime > current	
			}

			const dateIsIntersect = () => {
				const newSlotTime = new Date(futureColumnDate)
				newSlotTime.setHours(hour, minute, 0, 0)

				return this.slotCollection.isBusyTime({
					date: newSlotTime,
					duration: 60,
				})
			}

			const timeChecks = [
				{name: 'is-past', check: dateIsPast, disabled: this.calendar.workTime.isAllowPastTime},
				{name: 'is-intersect', check: dateIsIntersect}
			]

			const invalidCheck = timeChecks.find(check => {
				if (check.disabled)
					return false
				return check.check()
			})
			if (invalidCheck) {
				const date = new Date(futureColumnDate)
				date.setHours(hour, minute, 0, 0)
				const period = new TimePeriod(date, -1, 60)

				this.onInvalidTime({
					reason: invalidCheck.name,
					period: period,
				})
			} else if (this.timeIsWrong) {
				this.onValidTime()
			}

			this.timeIsWrong = !!invalidCheck
		},

		setTimeForPosition(posY, futureColumnDate) {
			let slotContainer = this.getCurrentSlotContainer()
			let relativePosY = posY + slotContainer.scrollTop - this.scheduleTableHeaderHeight - 12

            let position = relativePosY / this.cellHeight
            let currentHour = this.calendar.workTime.range()[Math.trunc(position)]
            let currentMinute = Math.round((position % 1) * 60);

			if (currentHour == undefined || currentMinute < 0)
                return
            if (currentHour == this.calendar.workTime.end.getHours() && 
				currentMinute == this.calendar.workTime.end.getMinutes())
                return


            if (currentMinute > 60) {
                currentHour += 1;
                currentMinute = 0;
            }

			this.checkValidTime(futureColumnDate, currentHour, currentMinute)

			let roundedTime = this.roundTime(currentHour, currentMinute)
			
			this.currentHour = roundedTime.hour
            this.currentMinute = roundedTime.minute
        },

		roundTime(currentHour, currentMinute){
			let movementStep = CONFIG["movement-step"]
			let roundedMinute = Math.round(currentMinute / movementStep) * movementStep
			if (roundedMinute == 60){
				currentHour += 1
				roundedMinute = 0
			}
			return {hour: currentHour, minute: roundedMinute}
		},

		onInvalidTime(data){
			if (!data)
				return
			const {reason, period} = data
			if (!period || reason != 'is-intersect')
				return 
			const currentInvalidTimeDate = new Date(period.start.date)
			
			// Send request to calc nearest free place
			const columnTitle = `column-${currentInvalidTimeDate.toLocaleDateString()}`
			eventBroker.$emit(columnTitle, 'define-nearest-free-place', period)
			
			// If last sended columns is not equal to current
			if (this.lastInvalidTimeDate && !isEqualDate(this.lastInvalidTimeDate, currentInvalidTimeDate)) {
				const lastDateColumnTitle = `column-${this.lastInvalidTimeDate.toLocaleDateString()}`
				eventBroker.$emit(lastDateColumnTitle, 'define-nearest-free-place', null)
			}

			this.lastInvalidTimeDate = new Date(period.start.date)
		},

		onValidTime(){
			if (!this.lastInvalidTimeDate)
				return
			const lastDateColumnTitle = `column-${this.lastInvalidTimeDate.toLocaleDateString()}`
			eventBroker.$emit(lastDateColumnTitle, 'define-nearest-free-place', null)
			this.lastInvalidTimeDate = null
		},

		getScrollSpeedY(relativeY, scrollBuffer, contHeight, ){
			
			// Scroll up container (if slot in scroll up area)
			if (relativeY - this.cellHeight <= scrollBuffer)
				return Math.floor(Math.abs(relativeY - this.cellHeight) / this.scrollStep) * -1
			
			// Scroll down container (if slot in scroll down area)
			if (relativeY - contHeight + this.cellHeight + 50 >= scrollBuffer)
				return Math.floor(Math.abs(relativeY - contHeight + this.cellHeight) / this.scrollStep) + 1

			return undefined
		},

		getScrollSpeedX(relativeX, scrollBuffer, contWidth){
			
			// Scroll left container (if slot in scroll left area)
			if (relativeX <= scrollBuffer + this.containerSizes["left"])
				return Math.floor(Math.abs(scrollBuffer + this.containerSizes["left"] - relativeX) / this.scrollStep) * -1
			
			// Scroll right container (if slot in scroll right area)
			if (contWidth - relativeX <= scrollBuffer)
				return Math.floor(Math.abs(scrollBuffer - (contWidth - relativeX)) / this.scrollStep) + 1

			return undefined
		},

		scrollContainer(){
			const relativeY = Math.round(this.posY - this.scheduleTableHeaderHeight - 12)
			const relativeX = Math.round(this.posX)

			const scrollBufferX = 50
			const scrollBuffer = 0
			const contHeight = this.containerSizes["height"]
			const scrollAreaY = this.containerSizes["scroll-area-y"]

			/* 
				Interrupt the table scrolling if 
				1. The slot is in the non-border area
				2. The slot is in the top border area and the table is scrolled to the top
				3. The slot is in the bottom border area and the table is scrolled to the bottom
			*/  
			
			this.isScrollBorderAreaX = !this.isMovingRight && relativeX <= scrollBufferX + this.containerSizes["left"] ||
										this.isMovingRight && this.containerSizes["width"] - relativeX <= scrollBufferX 

			this.isScrollBorderAreaY = relativeY - this.cellHeight <= scrollBuffer ||
										relativeY - contHeight + this.cellHeight + 50 >= scrollBuffer

			console.log({x : this.isScrollBorderAreaX, y: this.isScrollBorderAreaY});

			// Scroll function that shows the container reached a limit in Y axis
			
			const scrollConditionY = (target) => {

				if (this.isDeletePreparation) {
					return false
				}

				const currentRelativeY = Math.round(this.posY)

				const isTopBorderArea = currentRelativeY - this.cellHeight <= scrollBuffer
				const isBotBorderArea = currentRelativeY - contHeight + this.cellHeight + 50>= scrollBuffer 

				// console.log('cond y :>> ', {top : isTopBorderArea, bot: isBotBorderArea});

				if (isTopBorderArea && this.slotContainer.scrollTop != 0) {
					return target.scrollTop > 0
				} else if (isBotBorderArea) {
					return target.scrollTop < target.scrollHeight - target.clientHeight
				}
				return false
			}

			// Scroll function that shows the container reached a limit in X axis

			const scrollConditionX = (target) => {
				const currentRelativeX = Math.round(this.posX)


				// const isLeftBorderArea = currentRelativeX - this.columnWidth <= scrollBuffer && target.scrollLeft > (this.columnWidth + 1) * 3 + 10
				const isLeftBorderArea = !this.isMovingRight && currentRelativeX <= scrollBufferX + this.containerSizes["left"]
				const isRightBorderArea = this.isMovingRight &&  this.containerSizes["width"] - currentRelativeX <= scrollBufferX

				// console.log('scroll x cond :>> ', {x : currentRelativeX, left: isLeftBorderArea, right: isRightBorderArea});

				return isLeftBorderArea || isRightBorderArea
			}
			
			if (!this.isScrollBorderAreaX && !this.isScrollBorderAreaY){
				if (this.scrollInterval){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeedY = null
				this.scrollSpeedX = null
				return
			}

			// Update scroll speed for y axis

			if (!this.isScrollBorderAreaY) { 
				if (this.scrollInterval && !this.scrollSpeedX){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeedY = null
			} else {
				this.scrollSpeedY = this.getScrollSpeedY(relativeY, scrollBuffer, contHeight)
			}

			// Update scroll speed for x axis

			if (!this.isScrollBorderAreaX) {
				if (this.scrollInterval && !this.scrollSpeedY){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeedX = null
			} else {
				this.scrollSpeedX = this.getScrollSpeedX(relativeX, scrollBufferX, this.containerSizes["width"])
			}


			if (this.scrollInterval)
				return



			// Start scrolling using interval

			this.scrollInterval = scrollByCondition(this.slotContainer, {
				delay: 15,
				onScroll: this.onScrollContainerHandler,
				// scrollConditionX: scrollConditionX,
				scrollConditionY: scrollConditionY,
				scrollConditionX: scrollConditionX,
				getSpeedX: () => this.scrollSpeedX,
				getSpeedY: () => this.scrollSpeedY,
				onFinish: () => {
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
					this.scrollSpeedX = null
					this.scrollSpeedY = null
				}
			})	
		},

		onScrollContainerHandler(){

			if (this.scrollSpeedX && this.isScrollBorderAreaX){
				this.scrolledX += this.scrollSpeedX
				this.futureColumn = this.calcFutureColumn(this.posX, this.scrolledX)
			}
			if (this.scrollSpeedY && this.isScrollBorderAreaY){
				this.setTimeForPosition(this.posY, this.futureColumn)
			}

			if (this.isScrollBorderAreaX || this.isScrollBorderAreaY) {
				eventBroker.$emit('scrollable-table', 'exec-scroll')
			}

			// console.log('final x :>> ', this.posX);

			this.sendMoveEvent(this.posX, this.posY)
		},
		updateLimits(reachedLimit, addedColumnsCount) {

			// This function will be called by ScrollableTable when the moving slot
			// will reach left or right limit of scrollable table loaded part.   

			const allowedLimits = [
				'left', 
				'right'
			]

			if (!reachedLimit || !allowedLimits.includes(reachedLimit)) {
				throw Error("error : undefined limit is reached by slot card during a replace")
			}

			if (!this.dragging) {
				throw Error("error : standing slot card has no replace and limits for them")
			}


			// If reached limit of loaded scrollable table is right

			if (reachedLimit == 'right') {
				
				// Add relative placement of added columns to the end of array
				
				const lastOldColumn = this.columnsX[this.columnsX.length - 1] 
				const addedColumnsPosX = new Array(addedColumnsCount).fill(0)
				.map((_, index) => {
					return lastOldColumn + (index + 1) * (this.columnWidth + 1)
				})
				this.$set(this, 'columnsX', [...this.columnsX, ...addedColumnsPosX])
			}

			// If reached limit of loaded scrollable table is left

			if (reachedLimit == 'left' && this.scrollInterval) {
				
				// Add relative placement of added columns to the begin of array
				
				const firstOldColumn = this.columnsX[0] 
				const addedColumnsPosX = new Array(addedColumnsCount).fill(0)
				.map((_, index) => {
					return firstOldColumn - (addedColumnsCount - index) * (this.columnWidth + 1)
				})
				this.$set(this, 'columnsX', [...addedColumnsPosX, ...this.columnsX])

				// Recreate scroll interval because scroll interval contain old scrollLeft value of the scrollable table 

				clearInterval(this.scrollInterval)
				this.scrollInterval = null
				this.scrollContainer(this.posY, this.posX)
			}

		},


		handleEnd(event){

			if (!this.slotContainer)
				return 

			eventBroker.$emit('scrollable-table', 'exec-scroll-end')
			eventBroker.$emit('scrollable-table', 'disable-create-slot')
			eventBroker.$emit('scrollable-table', 'disable-slot-move-scroll')

			if (!this.isDeletePreparation && this.timeIsWrong) {
				const date = new Date(this.futureColumn)
				date.setHours(this.currentHour, this.currentMinute, 0, 0)
				const period = new TimePeriod(date, -1, 60)
				const nearestFreePlace = this.slotCollection.nearestFreePlace(period)
				if (nearestFreePlace) {
					this.$emit('on-drag-end', {
						minute: nearestFreePlace.start.date.getMinutes(),
						hour: nearestFreePlace.start.date.getHours(),
						date: nearestFreePlace.start.date,
					})
				} else {
					this.$emit('on-drag-end', null)
				}
				this.onValidTime()
			} else if (!this.isDeletePreparation){
				this.$emit('on-drag-end', {
					minute: this.currentMinute,
					hour: this.currentHour,
					date: this.futureColumn,
				})
			} else {
				this.$emit('on-drag-end', null)
			}

			if (this.scrollInterval) {
				clearInterval(this.scrollInterval)
				this.scrollInterval = null
			}

			this.colorizeFutureColumn(null)

			globalBus.$emit('static-menu', 'update-visible', {visible: true})
			
			this.$store.dispatch('set-test-event', null)

			this.dragging = null
			this.posX = 0
			this.futureColumn = null
			this.lastInvalidTimeDate = null
			this.columnsX = null
			this.posY = 0
			this.scrolledX = 0
			this.columnWidth = null
			if ('PointerEvent' in window) {
				this.$el.removeEventListener('pointermove', this.handleMove)
			} else {
				this.$el.removeEventListener('mousemove', this.handleMove)
			}

		},
	},
};
</script>

<style scoped>
#create-slot{

	position: absolute;

	background: white;
	border: 1.5px solid #0075ff;
	color: #0075ff;
	padding: 0 5px;

	max-width: 200px;
	border-radius: 4px;
	font-size: 10px;
	user-select: none;
}

#create-slot.invalid{
	border-color: #f14668;
	color: #f14668;
}

.draggable {
	z-index: 1001;
}

.current-time-line{
	border-top: 1.5px dashed #0075ff;
	position: absolute;
	top: 0;
}

.current-time-line.invalid{
	border-color: #f14668;
	color: #f14668;
}

.time-string{
	width: fit-content;
	background: white;
	width: 30px;
	text-align: end;
	padding-right: 4px;
}

.nearest-time{ 
	position: absolute;
	left: 2px;
	top: -24px;
	font-size: 10px;
	z-index: 10;
	background: white;
	border-radius: 4px;
	padding: 2px 4px;
	border: 1px solid #0075ff;
	color: #0075ff;
	width: fit-content;
}

</style>