<template>
	<div class="calendar-schedule-page" v-if="calendar && !calendar.isRestricted">
		 
		<!-- Date calendar -->

		<Calendar v-if="loadedDates && workTime"
			:loadedDates="loadedDates"
			:workTime="workTime"
			@on-swipe="inputMonthHandler"
			@on-select-date="selectDateHandler"
			@on-change-range="updateDateRangeHandler"
		/>

		<!-- Schedule -->
		<ScheduleTable
			:calendar="calendar"
			:loadedDates="loadedDates"
			:workTime="workTime"
			:isEditable="isEditable"
			@on-select-date="(date) => selectDateHandler(date, {
				isUpdateCalendar: true,
				isWithSmartSelect: false
			})"
		/>

		<SlotConfirmationModal
			:initSlot="openedConfirmationSlot"
		/>
	</div>
</template>

<script>
import YYYYMMDD, { convertTZ, getCurrentWeekV2, isEqualDate, nextDate, smartDateSelect, weekStart } from '../scripts/date';
import ScheduleTable from '../components/schedule/ScheduleTable.vue';
import Calendar from '../components/schedule/calendar/Calendar.vue';
import { globalBus } from '../GlobalEventBus.js';
import SlotConfirmationModal from '../components/modals/slot-confirmation-modal/SlotConfirmationModal.vue';

import CalendarWorkTime from '../scripts/calendarWorkTime';
import TimePeriod from '../scripts/timePeriod.js';
import CalendarLoadedDates from '../scripts/calendarLoadedDates.js';

export default {
    name: 'CalendarSchedule',
	components: {
		ScheduleTable,
		Calendar,
		SlotConfirmationModal,
	},
    data() {
        return {
			monthTakeWaitList: [],
			scrollTop: 0,

			currentMonthIndex: null,
			openedConfirmationSlot: null,
			
			loadedDates: null,
        };
    },
	computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},

		calendar(){
			if (!this.calendarUid)
				return undefined
			return this.$store.getters.getCalendarByUid(this.calendarUid)
		},

		calendarTimezone(){
			if (!this.calendar)
				return undefined
			const timezoneId = this.calendar.timezoneId
			return this.$store.getters.getTimezoneByID(timezoneId)
		},

		browserTimezone(){
            return this.$store.getters.browserTimezone
		},

		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		},

		workTime(){
			return !this.calendar ?
				new CalendarWorkTime(new Date(), new Date()) : this.calendar.workTime
		},

		isEditable(){
			return !this.calendar ?
				false : this.calendar.isEditable
		},
	},
	beforeRouteLeave (to, from, next) {

		globalBus.$off(`calendar-schedule-${this.calendarUid}`)

		let isSlotPage = to.name == 'slot-edit' || to.name == 'slot-attend'

		if (from.name == 'calendar-schedule') {
			this.saveCurrentScrollPosition()
		}
		this.saveCurrentSettings(to.name)
		next()
	},
    created() {
        // Setup initial data about months and orders
		const {selectedDate, daysCount } = this.setupQueryVariables()
		console.log({selectedDate, daysCount});
		if (!selectedDate || !daysCount) {
			return
		}

		this.loadedDates = new CalendarLoadedDates(selectedDate, daysCount)
		if (!this.loadedDates.months) {
			return
		} 

		this.setupSlots({monthIndex: this.loadedDates.currentMonthIndex})
	},
	mounted() {
		globalBus.$on(`calendar-schedule-${this.calendarUid}`, 'add-slot', (slots) => {
			this.slotCollection.addSlots(slots)
		})
	},
	beforeDestroy() {
		globalBus.$off(`calendar-schedule-${this.calendarUid}`)
	},

	watch: {
		// selectedDate(newDate, oldDate) {
		// 	if (!oldDate || newDate == oldDate)
		// 		return

		// 	let query = {
		// 		dt: YYYYMMDD(newDate),
		// 	}

		// 	this.updateUrl(query)
		// },
		// daysCount(newDays, oldDays) {
		// 	if (!oldDays || newDays == oldDays)
		// 		return

		// 	let query = {
		// 		dr: newDays,
		// 		dt: YYYYMMDD(this.selectedDate),
		// 	}

		// 	if (newDays == 7)
		// 		this.selectedDate = weekStart(this.selectedDate)
		// 	else if (newDays > oldDays) {
		// 		this.selectedDate = smartDateSelect(this.selectedDate, newDays)
		// 	}

		// 	this.updateUrl(query)
		// },
		'loadedDates.selected' : {
			handler: function(newData, oldData) {
				if (newData === oldData || !newData) {
					return
				}

				let query = {
					dt: YYYYMMDD(newData.start.date),
					dr: newData.daysCount,
				}

				this.updateUrl(query)
			},
			deep: true,
			immediate: true
		},
		'$route.params.calendarUid': {
			handler: function(newCalendarUid, oldCalendarUid) {
				if (newCalendarUid == oldCalendarUid)
					return

				const {selectedDate, daysCount} = this.setupQueryVariables()

				let calendarUid = newCalendarUid

				if (this.loadedDates == null || !this.loadedDates.months || this.loadedDates.months.length == 0) {
					this.loadedDates = new CalendarLoadedDates(selectedDate, daysCount)
				}
				
				if (this.slotCollection && this.slotCollection.calendarUid != calendarUid){
					this.setupSlots({monthIndex: this.loadedDates.currentMonthIndex})	
				}
			},
			deep: true,
			immediate: true
		},
	},

    methods: {
		saveCurrentScrollPosition(){
			const table = document.getElementById('schedule-table')
			if (!table) {
				return
			}
			const activeCarouselSlide = table.querySelector('.carousel-slide.is-active')
			if (!activeCarouselSlide)
				return
			const activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return
			const scroll = activeCalendarTable.scrollTop
			sessionStorage.setItem(`schedule-table-${this.calendarUid}-scroll`, scroll)
		},

		saveCurrentSettings(newRouteName){
			let prefix 
			
			if (newRouteName == 'slot-edit') {
				prefix = 'edit'
			} else if (newRouteName == 'slot-attend') {
				prefix = 'attend'
			} else if (newRouteName == 'calendar-settings-attenders-menu') {
				prefix = 'attenders'
			} else if (newRouteName == 'calendar-settings-menu') {
				prefix = 'settings'
			} else if (newRouteName == 'calendar-settings-host-menu') {
				prefix = 'hosts'
			} else if (newRouteName == 'account-settings') {
				prefix = 'account'
			} else {
				return
			}
			
			const currentPageData = {
				name: 'calendar-schedule',
				params: this.$route.params,
				query: this.$route.query
			}
			localStorage.setItem(`originPage-${prefix}`, JSON.stringify(currentPageData))
		},

		inputMonthHandler(newMonthIndex){
			
			if (newMonthIndex == this.loadedDates.currentMonthIndex)
				return

			const isFirstMonth = this.loadedDates.isFirstMonth(newMonthIndex)
			const isLastMonth = this.loadedDates.isLastMonth(newMonthIndex)
			if (isFirstMonth || isLastMonth) {
				const newDateCenter = this.loadedDates.months[newMonthIndex].firstDate
				this.loadedDates.setupMonths(newDateCenter)
			}

			// this.loadedDates.currentMonthIndex = newMonthIndex

			this.setupSlots({monthIndex: newMonthIndex})
		},

		dateRangeOutOfWeek(date, daysCount, weekStart){
			if (daysCount == 1 || daysCount == 7 && date.getDay() == weekStart)
				return false
			if (daysCount == 7 && date.getDay() != weekStart)
				return true

			const dateRangeStart = date			
			const dateRangeStartDay = dateRangeStart.getDay() || 7
			const dateRangeEnd = nextDate(date, daysCount - 1)
			const dateRangeEndDay = dateRangeEnd.getDay() || 7

			return dateRangeEndDay < dateRangeStartDay
		},

		setupQueryVariables(){

			const result = {}

			// Setup selected date
			let date = this.$route.query.dt ?
				new Date(this.$route.query.dt) :
			 	new Date()

			// Setup days count
			const dateRange = Number(this.$route.query.dr)
			result["daysCount"] = dateRange ? dateRange : 1

			// Setup selected date
			const isDateRangeOutOfWeek = this.dateRangeOutOfWeek(date, result["daysCount"], 1)
			console.log('isDateRangeOutOfWeek :>> ', isDateRangeOutOfWeek);
			result["selectedDate"] = isDateRangeOutOfWeek ?
				smartDateSelect(date, result["daysCount"]) : date

			return result
		},

		setupSlots({monthIndex, start, end}){

			if (monthIndex == undefined && start == undefined && end == undefined)
				return

			if (monthIndex != undefined) {
				const currentMonth = this.loadedDates.months[monthIndex]
				start = currentMonth.from
				end = currentMonth.to
			}

			if (!this.browserTimezone || !this.calendarTimezone)
				return setTimeout(() => this.setupSlots({monthIndex, start, end}), 100)

			const browserTz = this.browserTimezone
			const calendarTz = this.calendarTimezone

			const from = convertTZ(start, this.calendarTimezone, this.browserTimezone)
			const to = convertTZ(end, this.calendarTimezone, this.browserTimezone)

			const paramsIsEqual = this.slotCollection.loadingParamsIsEqual(from, to, browserTz, calendarTz) 
			if (this.slotCollection.isLoading || paramsIsEqual)
				return
			this.slotCollection.updateSlots(from, to, browserTz, calendarTz)
				.then(this.setupConfirmationSlot)
		},

		setupConfirmationSlot(){
			if (this.openedConfirmationSlot)
				return
			const confirmationSlotId = this.$route.query['m_scm']
			if (!confirmationSlotId || Number(confirmationSlotId) == NaN)
				return
			const slotId = Number(confirmationSlotId)
			const slot = this.slotCollection.getById(slotId)
			if (!slot)
				return
			this.openedConfirmationSlot = slot
		},

		selectDateHandler(newDate, opts={isUpdateCalendar:true, isWithSmartSelect:true}){

			if (opts.isUpdateCalendar && !this.loadedDates.includes(newDate)) {
				this.setupSlots({monthIndex: this.loadedDates.currentMonthIndex})
			}

			this.loadedDates.select(newDate, opts.isWithSmartSelect)
		},

		updateDateRangeHandler(newDateRange){
			this.loadedDates.resize(newDateRange)
		},

		updateUrl(query){
			if (this.$route.query.dt != undefined && query.dt == undefined)
				query.dt = this.$route.query.tb

			if (this.$route.query.tb != undefined && query.tb == undefined)
				query.tb = this.$route.query.tb

			if (this.$route.query.st != undefined && query.st == undefined)
				query.st = this.$route.query.st

			if (this.$route.query.dr != undefined && query.dr == undefined)
				query.dr = this.$route.query.dr

			this.$router.replace({
				name : 'calendar-schedule',
				params: {
					calendarUid: this.calendarUid
				},
				query: query,
			}).catch(() => {})
		}
	},
};
</script>

<style>
.carousel-slide{
	height: 100%;
}
.carousel-slides{
	height: 100%;
	/* overflow-y: hidden; */
}
</style>

<style scoped>

.calendar-schedule-page{
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.calendar-schedule-page > *:first-child {
	flex: 0 0 auto;
}

#schedule-calendar{
	box-shadow: none;
}

.header-title{
	font-size: 20px;
	font-weight: 600;
	position: fixed;
	z-index: 3;
	padding: 10px;
	width: calc(100% - 54px);
	height: 54px;
	display: flex;
	align-items: center;
}

.carousel-list{
	background: white;
	width: 100%;
}

</style>