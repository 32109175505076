<template>
	<div class="past-time-backdrop" v-if="backdropIsActive" v-bind:style="{
		display: backdropIsActive  ? 'block' : 'none',
		filter: boxShadow,
	}">

		<div class="past-time-backdrop__shape" v-bind:style="{
			'clip-path': clipPath,
			'width': `${backdropWidth}px`,
			'height': `${backdropHeight}px`,
		}"></div>

		<!-- <div class="past-time-backdrop__item" 
		v-for="item in tableHeader.week"
		:key="`past-time-backdrop-${item.date.toLocaleDateString()}`"
		v-bind:style="{
			display: dateIsPast(item.date) ? 'block' : 'none',
			height: `${calcPastTimeHeight(item.date)}px`,
			backgroundColor: color,
			width: `${columnWidth + 1}px`,
		}"></div> -->
	</div>
</template>

<script>
import CalendarHeader from '../../scripts/calendarHeader';
import CalendarLoadedDates from '../../scripts/calendarLoadedDates';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import { dateIsPast, isToday } from '../../scripts/date';

export default {
	name: 'PastTimeBackdrop',
	props: {
		loadedDates: {
			type: CalendarLoadedDates,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		container: {
			type: HTMLElement,
		},
		color: {
			type: String,
			default: "#c2c2c2",
		},
		columnCount: {
			type: Number,
			default: 7,
		}
	},
	computed: {
		backdropIsActive(){
			return this.loadedDates && dateIsPast(this.loadedDates.from)
		},
		containerElement(){
			if (this.container)
				return this.container
			if (this.$el)
				return this.$el.parentElement
			return null
		},
		boxShadow(){
			return `drop-shadow(0 0 6px ${this.color})`
		},

	},

	watch: {
		container(){
			this.clipPath = this.calcClipPath()
		},
		columnCount(){
			this.clipPath = this.calcClipPath()
		},
		workTime(){
			this.clipPath = this.calcClipPath()
		},
		loadedDates: {
			handler(){
				this.clipPath = this.calcClipPath()
			},
			deep: true,
		}
	},

	data() {
		return {
			clipPath: null,
			backdropHeight: 0,
			backdropWidth: 0,
		}
	},

	mounted(){
		this.clipPath = this.calcClipPath()
        setInterval(() => {this.clipPath = this.calcClipPath()}, 60000)
	},

	methods: {
		dateIsPast: dateIsPast,
		calcContainerWidth(){
			if(!this.containerElement)
				return 0
			return this.containerElement.scrollWidth
		},
		calcPastTimeHeight(date){
			if (!date || !dateIsPast(date) || !this.containerElement)
				return 0
			const redLine = this.containerElement.querySelector('.red__line')
			if (!redLine)
				return 0
			const redLineTop = redLine.style["top"].replace('px', '')
			if (Number(redLineTop) == NaN)
				return 0
			return (Number(redLineTop))
		},
		calcColumnSizes(){
			this.columnCount
			const container = this.container
			if (!container)
				return null
			const columnElem = container.querySelector('.calendar-column')
			if (!columnElem)
				return null
			const columnRect = columnElem.getBoundingClientRect()
			return {
				width : Math.round(columnRect.width),
				height: Math.round(columnRect.height),
			}
		},
		calcPastDateCount(){
			const pastDates = this.tableHeader.visibleDateRange()
				.filter(date => dateIsPast(date.date))

			return pastDates.length
		},
		calcColumnLeft(date){
			const columnsElements = Array.from(this.containerElement.querySelectorAll('.calendar-column'))
			if (!columnsElements || columnsElements.length == 0)
				return 0
			const dateColumnElem = columnsElements.find(elem => {
				return elem.id == `column-${date.toLocaleDateString()}`
			})
			if (!dateColumnElem) {
				return 0
			}
			const dateColumnLeft = dateColumnElem.getBoundingClientRect().left
			return dateColumnLeft
		},

		calcClipPath(){
			const visibleDates = this.loadedDates.visibleDateRange()
			const firstVisibleDate = visibleDates[0]
			const lastVisibleDate = visibleDates[visibleDates.length - 1]

			const isFirstDatePast = dateIsPast(firstVisibleDate)
			const isLastDatePast = dateIsPast(lastVisibleDate)

			if (!isFirstDatePast && !isLastDatePast)
				return `inset(0 0 0 0)`

			// if (isFirstDatePast && this.columnCount == 1 && !isToday(this.week.date))
			// 	return `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
			// else if (!isFirstDatePast)	
			// 	return `inset(0 0 0 0)`
				
			const columnSizes = this.calcColumnSizes()
			console.log('sizes :>> ', columnSizes);
			if (!columnSizes || !columnSizes.height || !columnSizes.width) {
				return `inset(0 0 0 0)`
			} else if (isLastDatePast && !isToday(lastVisibleDate)) {
				this.backdropHeight = columnSizes.height
				this.backdropWidth = this.calcContainerWidth()
				return `polygon(0 0, ${this.backdropWidth} 0, ${this.backdropWidth} ${this.backdropHeight}, 0 ${this.backdropHeight})`
			}

			const currentDateColumnLeft = this.calcColumnLeft(new Date())
			const firstDateColumnLeft = this.calcColumnLeft(firstVisibleDate)
			const backdropWidth = Math.round(currentDateColumnLeft - firstDateColumnLeft + columnSizes.width + 1)
			
			this.backdropWidth = backdropWidth
			this.backdropHeight = columnSizes.height

			if (isLastDatePast && !isToday(lastVisibleDate))
				return `polygon(0 0, ${this.backdropWidth} 0, ${this.backdropWidth} ${this.backdropHeight}, 0 ${this.backdropHeight})`
			
			const today = new Date()
			today.setHours(0, 0, 0, 0)
			const todayColumnHeight = this.calcPastTimeHeight(today)

			return `polygon(0 0, 
				${backdropWidth}px 0, 
				${backdropWidth}px ${todayColumnHeight}px,
				${backdropWidth - columnSizes.width + 1}px ${todayColumnHeight}px,
				${backdropWidth - columnSizes.width + 1}px ${columnSizes.height}px,
				0 ${columnSizes.height}px)`		
		},

	},
};
</script>

<style scoped>

.past-time-backdrop {
	position: absolute;
	top: 10px;
	bottom: 0;
	left: 30px;
	right: 16px;
	opacity: 0.2;
	border-radius: 2px;
}

.past-time-backdrop__shape{
	position: absolute;
	top: 10px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background: rgb(193, 193, 193);
	border-radius: 2px;
}

/* .past-time-backdrop::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(205, 17, 17, 1); 
    z-index: -1;
    border-radius: 10px; 
} */


</style>