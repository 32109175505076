var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.backdropIsActive)?_c('div',{staticClass:"past-time-backdrop",style:({
	display: _vm.backdropIsActive  ? 'block' : 'none',
	filter: _vm.boxShadow,
})},[_c('div',{staticClass:"past-time-backdrop__shape",style:({
		'clip-path': _vm.clipPath,
		'width': (_vm.backdropWidth + "px"),
		'height': (_vm.backdropHeight + "px"),
	})})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }