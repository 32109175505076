<template>
	<div class="tools-container" :id="`tools-container-${eventSlot.index}`">
		<div v-for="tool in slotTools"
		:key="`tool-${tool.icon}`" 
		:id="`tool-${tool.name}`" 
		class="tool-button">
			<b-icon :icon="tool.icon" 
				custom-size="mdi-18px"
				v-bind:style="{
					'color': slotIconColor
				}">
			</b-icon>
		</div>
	</div>
</template>

<script>

class Tool {
	constructor(icon, name, action, type=null){
		this.icon = icon
		this.name = name
		this.action = action
		this.type = type
	}
}

export default {
	name: 'BaseSlotToolsButtons',
	
	emits: [
		'on-edit',
		'on-toggle-more',
	],

	props: {
		eventSlot: {
			type: Object,
			required: true,
		},

		slotToolsIsOpen: {
			type: Boolean,
			required: true,
		},
		viewType: {
			type: String,
			required: true,
		},
		slotIsPast: {
			type: Boolean,
			required: true,
		},
	},

	computed: {
		slotTools(){
			if (this.viewType == 'short')
				return null

			let tools = []

			tools.push(new Tool('pencil', 'edit', this.openSettings))
			tools.push(new Tool('dots-vertical', 'more', this.toggleTools))

			// if (this.slotIsPast || this.viewType == 'middle')
			if (this.viewType != 'full')
				tools = tools.filter(tool => tool.name != "edit")

			if (this.slotToolsIsOpen) {
				let moreTool = tools.find(tool => tool.name == 'more')
				moreTool.icon = 'close'
				moreTool.action = this.closeTools
			}

			return tools
		},
		slotIconColor(){
			return this.eventSlot.settings.cellTextColor ?
				this.eventSlot.settings.cellTextColor : null
		},
	},

	mounted() {
		this.$nextTick(this.setupHandlers)
	},

	updated() {
		this.$nextTick(this.setupHandlers)
	},

	beforeDestroy() {
		const toolsContainer = document.getElementById('tools-container-' + this.eventSlot.index)
		if (!toolsContainer)
			return
		const tools = Array.from(toolsContainer.querySelectorAll('.tool-button'))
		tools.forEach(tool => {
			if (tool.id == 'tool-more')
				tool.removeEventListener('pointerdown', this.toggleTools)
			if (tool.id == 'tool-edit')
				tool.removeEventListener('pointerdown', this.openSettings)
		})
	},

	methods: {
		setupHandlers(){
			const toolsContainer = document.getElementById('tools-container-' + this.eventSlot.index)
	
			const tools = Array.from(toolsContainer.querySelectorAll('.tool-button'))
			tools.forEach(tool => {
				if (tool.id == 'tool-more')
					tool.addEventListener('pointerdown', this.toggleTools)
				if (tool.id == 'tool-edit')
					tool.addEventListener('pointerdown', this.openSettings)
			})
		},

		toggleTools(){
			this.$emit('on-toggle-more')
		},
		closeTools(){
			this.$emit('on-toggle-more')
		},
		openSettings(){
			this.$emit('on-edit')
		},
	},
};
</script>

<style scoped>

i{
	user-select: none;
}

.tools-container{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 4;

	display: flex;
	flex-direction: row;
	gap: 12px;
	padding: 12px 0;
	padding-right: 0;
}

.tool-button{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 24px;
	background: inherit;
}
</style>